@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&family=Poppins:wght@200;400;700&display=swap");

/* #4dd2ff
#1A1A1A
#272727 
BFD7EA
*/

* {
  margin: 0;
  padding: 0;
  color: white;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.1rem;
}

img {
  width: 100%;
}

.pages-container {
  min-height: 100vh;
  background-color: #1a1a1a;
  /* border: 2px solid red; */
}

.pages-header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.pages-name {
  margin: 50px 0;
  border-bottom: #4dd2ff;
  font-size: 2rem;
}

/* .header-backdrop {
  background: #4dd2ff;
  width: 80px;
  height: 10px;
} */

.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 2px solid yellow; */
}

.home-short-about {
  font-size: 1.5rem;
  font-weight: 400;
  max-width: 850px;
  width: 100%;
}

.home-short-about p {
  padding: 25px 50px;
  text-align: justify;
}

.hollow-outline {
  font-size: 10.5em;
  color: #1a1a1a;
  -webkit-text-fill-color: #1a1a1a;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #4dd2ff;
  position: relative;
}

.home-name {
  list-style: none;
  display: flex;
}

.home-name-element:hover {
  cursor: pointer;
  -webkit-text-fill-color: #4dd2ff;
}

.home-links {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 40%;
  margin: 20px 0;
}

.view-work-btn {
  border: none;
  color: white;
  border-radius: 4px;
  transition: ease-out 0.3s;
  padding: 10px;
  font-size: 1rem;
  outline: none;
  margin: 8px 0;
  border: 2px solid #4dd2ff;
  z-index: 1;
  background-color: #1a1a1a;
}

.view-work-btn:hover {
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;
}

.view-work-btn:before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  right: 50%;
  left: 50%;
  bottom: 0;
  opacity: 0;
  content: "";
  background-color: #4dd2ff;
}

.view-work-btn:hover:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
  z-index: -1;
}

.view-work-btn:active:before {
  background-color: #fff;
}

.navbar {
  display: flex;
  height: 80px;
  justify-content: flex-end;
  align-items: center;
  background-color: #272727;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.6);
}

.nav-mylogo {
  list-style: none;
  margin: auto;
  margin-left: 0;
  margin-right: auto;
  padding: 0 30px;
  font-weight: 600;
  font-size: 1.5rem;
  transition: 0.2s;
  text-align: center;
}

.my-logo:hover {
  cursor: pointer;
  color: #4dd2ff;
  transition: 0.2s;
}

.my-logo:active {
  color: #4dd2ff;
  transition: 0.2s;
  transition: 0.1s;
  color: #d3d3d3;
}

.navbar > .active {
  border-bottom: 3px solid #4dd2ff;
}

.navbar-elements {
  font-size: 1rem;
  font-weight: 700;
  padding: 25px;
  background-color: #272727;
  transition: 0.2s;
}

.navbar-elements:hover {
  cursor: pointer;
  color: #4dd2ff;
}

.navbar-elements:active {
  display: flex;
  justify-content: flex-end;
  transition: 0.1s;
  color: #d3d3d3;
}
.nav-hamburger-container {
  display: none;
  position: sticky;
  text-align: center;
  top: 80px;
  right: 0;
  background-color: #272727;
}

.ham-elements-container {
  position: sticky;
  background-color: #1a1a1a;
}

.navbar-ham-elements {
  font-size: 1rem;
  font-weight: 700;
  padding: 25px;
  background-color: #272727;
  transition: 0.2s;
}

.navbar-ham-elements:hover {
  color: #4dd2ff;
}

.navbar-ham-elements:active {
  transition: 0.1s;
  color: #d3d3d3;
}

.nav-hamburger-btn {
  display: none;
  background-color: transparent;
  border: 0;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
}

.nav-hamburger-btn:focus {
  outline: none;
}

#aboutme {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.about-desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
}

.skills-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.skills-card-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  max-width: 1200px;
  width: 80%;
  margin-top: 50px;
  /* border: 2px solid red; */
}

.skills-card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 200px;
  height: 200px;
  margin: 25px;
  border: 2px solid #272727;
  border-radius: 12px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.6);
}

.skills-logo-container {
  width: 100px;
  height: 100px;
}

.skills-logo-container img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

#projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.project-intro-container {
  margin: 50px 0;
  padding: 0 25px;
  font-size: 1.35rem;
}

.page-intro {
  max-width: 500px;
  margin: 10px 0;
  font-size: 1rem;
}
.project-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  z-index: 200;
}

.project-icon {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}

.project-card {
  display: flex;
  justify-content: space-between;
  position: relative;
  max-width: 900px;
  background-color: #1a1a1a;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.6);
  border-radius: 12px;
  margin: 0 25px;
  padding: 10px 20px;
}

.draw-border {
  box-shadow: inset 0 0 0 3px #fff;
  color: #fff;
  transition: color 0.25s 0.0833333333s;
  position: relative;
}

.draw-border::before,
.draw-border::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
}

.draw-border::before {
  border-bottom-width: 3px;
  border-left-width: 3px;
}

.draw-border::after {
  border-top-width: 3px;
  border-right-width: 3px;
}

.draw-border:hover {
  color: #4dd2ff;
}

.draw-border:hover::before,
.draw-border:hover::after {
  border-color: #4dd2ff;
  transition: border-color 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}

.draw-border:hover::before {
  transition-delay: 0s, 0s, 0.25s;
}

.draw-border:hover::after {
  transition-delay: 0s, 0.25s, 0s;
}

.see-more-button {
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.5;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  margin: 8px 0;
}

.project-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project-card-image {
  display: flex;
  align-items: center;
  max-width: 400px;
  padding: 15px;
}

.project-card-image > a > img {
  width: 100%;
  border-radius: 12px;
}

.project-details {
  max-width: 450px;
  padding: 20px;
}

.project-name {
  font-size: 1.5rem;
}

.project-stack {
  display: flex;
  flex-wrap: wrap;
}

.stack-element {
  list-style: none;
  margin: 8px;
  padding: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: 0.2s;
  border-radius: 8px;
}

.stack-element:hover {
  transition: 0.2s;
  color: #4dd2ff;
  border: 1px solid #4dd2ff;
  cursor: pointer;
}

.project-about {
  margin: 25px 0;
  max-height: 250px;
  overflow-y: scroll;
  padding-right: 25px;
}

/* width */
.project-about::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.project-about::-webkit-scrollbar-track {
  background: #1a1a1a;
}

/* Handle */
.project-about::-webkit-scrollbar-thumb {
  background: #4dd2ff;
}

/* Handle on hover */
.project-about::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.project-links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0;
  font-size: 1.15rem;
}

.project-links a {
  text-decoration: none;
  margin: 2px;
  transition: 0.2s;
}

.project-links a:hover {
  text-decoration: underline;
  transition: 0.2s;
  color: #4dd2ff;
}

.hide-button {
  max-width: 300px;
  border-radius: 25px;
  background-color: transparent;
  border: 0;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
  transition: 0.2s;
}

.hide-button:hover {
  transform: scale(1.2);
}

.hide-button:active {
  transform: scale(1);
}

.project-image {
  max-width: 400px;
  margin: 50px;
  background-color: #272727;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.project-icon-element {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 12px;
}

.project-highlight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #272727;
  padding: 20px 30px;
  border-radius: 12px;
}

.pro-icon-body {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.project-highlight-element {
  margin: 15px 0;
}

.deployment-link:active {
  transform: scale(1);
  transition: 0.2s;
}

.github-logo {
  width: 50px;
  transition: 0.2s;
}

.github-logo:hover {
  transform: scale(1.1);
  transition: 0.2s;
}

.github-logo:active {
  transform: scale(1);
  transition: 0.2s;
}

.home-logos {
  margin: 10px;
  width: 100%;
  max-width: 300px;
}

.home-links-elements {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 65px;
  width: 20%;
  max-width: 500px;
}

#resume {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
}

.display-resume {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.resume-card {
  max-width: 800px;
  margin: 50px 25px;
  padding: 50px;
  border: 2px solid #272727;
  border-radius: 12px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.6);
}

.resume-header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #272727;
}

.company-logo {
  max-width: 100px;
  margin-right: 30px;
}

.company-logo img {
  border-radius: 12px;
}

.company-details {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.company-link {
  text-decoration: none;
}

.company-name:hover {
  transition: 0.2s;
  color: #4dd2ff;
  text-decoration: underline;
}

.detail-element {
  margin: 12px 0;
}

.position-dates {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

#contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}
.call-to-action {
  text-align: center;
  margin: 20px 10px;
}

.email-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
  max-width: 800px;
  margin: 50px 50px;
  border: 2px solid #272727;
  border-radius: 12px;
  background-color: #272727;
  padding: 5%;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.6);
}

.email-form > input {
  color: #fff;
  background-color: transparent;
  border: 0;
  outline: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  margin: 15px 0;
  font-style: inherit;
  text-indent: 5px;
}

.email-form > textarea {
  color: #fff;
  background-color: transparent;
  border: 0;
  outline: 0;
  border: 1px solid rgba(255, 255, 255, 0.6);
  margin: 15px 0;
  padding: 8px;
  min-height: 150px;
  font-style: inherit;
  text-indent: 5px;
  transition: 0.2s;
  border-radius: 12px;
}

.email-form > input:focus {
  transition: 0.2s;
  border-bottom: 1px solid rgba(77, 210, 255, 0.6);
}

.email-form > textarea:focus {
  transition: 0.2s;
  border: 1px solid rgba(77, 210, 255, 0.6);
}

.email-confirm-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
}

.email-popup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  min-width: 150px;
  position: relative;
  background-color: #1a1a1a;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.6);
  border-radius: 12px;
  margin: 0 25px;
  padding: 30px 20px;
}

.email-popup > div > button {
  margin: 0 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-bottom: 1px solid rgba(77, 210, 255, 0.6);
  -webkit-text-fill-color: #4dd2ff;
  box-shadow: 0 0 0px 1000px transparent inset;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.footer {
  display: flex;
  flex-direction: column;
  height: 80px;
  justify-content: space-evenly;
  align-items: center;
  background-color: #272727;
}

.footer-element {
  font-size: 1.25rem;
  font-weight: 700;
  transition: 0.2s;
}

.footer-element:hover {
  cursor: pointer;
  color: #4dd2ff;
}

.footer-element:active {
  transition: 0.1s;
  color: #d3d3d3;
}

@media screen and (max-width: 850px) {
  .hollow-outline {
    font-size: 9.5rem;
  }

  .home-short-about {
    font-size: 1.25rem;
  }

  .project-card {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 700px) {
  .hollow-outline {
    font-size: 7.5rem;
  }

  .project-card {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .resume-header {
    flex-direction: column;
  }

  .company-logo {
    margin: 0;
  }
}

@media screen and (max-width: 600px) {
  .nav-hamburger-btn {
    position: absolute;
    right: 30px;
    display: block;
  }

  .nav-hamburger-container {
    display: block;
  }

  .navbar {
    justify-content: flex-end;
  }

  .navbar-elements {
    display: none;
  }

  .hollow-outline {
    font-size: 6rem;
  }

  .home-short-about {
    font-size: 1rem;
  }

  .navbar-elements {
    font-size: 0.8rem;
  }

  .company-details {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .company-logo {
    margin: 0;
  }

  .skills-card {
    width: 150px;
    height: 150px;
    margin: 25px;
  }

  .skills-logo-container {
    width: 40%;
    height: 40%;
  }

  .position-dates {
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .company-loc {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
@media screen and (max-width: 450px) {
  .hollow-outline {
    font-size: 4rem;
  }

  .home-short-about {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 325px) {
  html {
    font-size: 12px;
  }
  .hollow-outline {
    font-size: 3rem;
  }

  .home-short-about {
    font-size: 0.7rem;
  }

  .home-links-elements {
    min-width: 50px;
  }

  .home-links {
    flex-wrap: wrap;
  }
}

/* @media (min-width:320px)  
@media (min-width:481px) 
@media (min-width:641px)  
@media (min-width:961px)  
@media (min-width:1281px) */
